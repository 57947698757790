import styled from "styled-components";

export const H1Tag = styled.h1<any>`
  font-size: 3.5rem;
  line-height: 4.5rem;
  font-weight: 500;
  margin: var(--margin, 0);
  font-family: "Raleway" !important;
  color: var(--color, #4a4a4a);

  ${(props) =>
    props.clipText &&
    `
    background: linear-gradient(92.2deg, #FFE2E8 0.47%, #FFFCFC 25.07%, #8CC8EA 64.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    width: fit-content;
    font-weight:600;
  `}
`;

export const H2Tag = styled.h2`
  font-size: 3rem;
  line-height: 4rem;
  font-weight: 500;
  margin: var(--margin, 0);
  font-family: "Raleway" !important;
  color: var(--color, #4a4a4a);
`;

export const H3Tag = styled.h3`
  font-size: 2.5rem;
  line-height: 3.375rem;
  font-weight: 500;
  margin: var(--margin, 0);
  font-family: "Raleway" !important;
  color: var(--color, #4a4a4a);
  text-align: var(--textAlign, left);
`;

export const H4Tag = styled.h4<any>`
  font-size: 2rem;
  line-height: 3rem;
  font-weight: 500;
  margin: var(--margin, 0);
  font-family: "Raleway" !important;
  color: var(--color, #4a4a4a);

  ${(props) =>
    props.clipText &&
    `
    background: linear-gradient(92.2deg, #FFE2E8 0.47%, #FFFCFC 25.07%, #8CC8EA 64.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    width: fit-content;
    font-weight:600;
  `}
`;

export const H5Tag = styled.h5<any>`
  font-size: 1.5rem;
  line-height: 2rem;
  margin: var(--margin, 0);
  font-family: "Raleway" !important;
  color: var(--color, #4a4a4a);
  font-weight: var(--fontWeight, 500);

  ${(props) =>
    props.clipText &&
    `
    background: linear-gradient(92.2deg, #FFE2E8 0.47%, #FFFCFC 25.07%, #8CC8EA 64.68%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    width: fit-content;
    font-weight:600;
  `}
`;

export const H6Tag = styled.h6`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: var(--fontWeight, 500);
  margin: var(--margin, 0);
  font-family: "Raleway" !important;
  color: var(--color, #4a4a4a);
`;

export const ParaTag = styled.div`
  font-family: "Open Sans";
  color: var(--color, #4a4a4a);
  font-weight: var(--fontWeight, 400);
  font-size: var(--fontSize, 0.875rem);
  margin: var(--margin, 0);
  line-height: var(--lineHeight, 22px);
  width: var(--paraWidth, 100%);
  text-align: var(--textAlign, left);
`;

export const SpanTag = styled.span`
  font-family: "Open Sans";
  color: var(--color, #4a4a4a);
  font-size: var(--fontSize, 0.875rem);
  font-weight: var(--fontWeight, 400);
  margin: var(--spanmargin, 0);
  line-height: var(--lineHeight, 22px);
  display: var(--display, inline);
  flex-shrink: var(--spanFlex, unset);
  text-align: var(--textAlign, left);
  display: var(--spanDisplay, inline);
`;

export const AnchorTag = styled.a`
  font-family: "Open Sans";
  color: var(--color, #008cff);
  font-size: var(--fontSize, 0.875rem);
  font-weight: var(--fontWeight, 400);
  margin: var(--anchormargin, 0);
  line-height: var(--lineHeight, 22px);
  display: var(--display, inline);
  flex-shrink: var(--anchorFlex, unset);
`;
