import styled from "styled-components";

export const LandingFaq = styled.div`
  display: flex;
  background: #fff;
  flex-direction: column;
  padding: 16px 24px;
  margin-bottom: 1rem;
`;

export const LandingFaqListItem = styled.div`
  margin-bottom: 16px;
  padding: 16px 24px;
  position: relative;
  cursor: pointer;
  &:after {
    content: "";
    position: absolute;
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
    right: 12px;
    top: 33px;
    transition: 0.3s ease-in;
  }
  &.close:after {
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
  }
  &.open:after {
    transform: rotate(-135deg);
    -webkit-transform: rotate(-135deg);
  }
  &:hover {
    background: #ffffff;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
    border-radius: 6px;
  }
  &:last-child {
    margin-bottom: unset;
  }
`;

export const LandingFaqList = styled.ul<any>`
  width: 100%;
  display: flex;
  flex-direction: column;
  ${(props) =>
    props.bnplFaq &&
    `
    gap:32px;
    font-size:14px;
    & li{
      padding:unset !important;
    }
    & li:after {
      top:5px !important;
    }
    & li:hover{
      box-shadow:unset !important;
    }
 `}
`;

export const LandingFaqQues = styled.h5<any>`
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #000;
  font-family: "Open Sans";
  font-weight: bold;

  ${(props) =>
    props.bnplFaq &&
    `
  font-size: 16px;
  line-height: 20px;
 `}
`;
