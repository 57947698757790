import React from "react";

import * as S from "./TextStyles";

const H4Tag = ({ children, margin, color, clipText }: any) => {
  return (
    <S.H4Tag
      clipText={clipText}
      style={{ "--color": color, "--margin": margin } as any}
    >
      {children}
    </S.H4Tag>
  );
};

export default H4Tag;
