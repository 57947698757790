import { useState } from "react";
import { PlainMarkdown } from "../markdown";
import H4Tag from "../TextStyles/H4Tag";
import * as S from "./LandingFaq.style";

const LandingFaqMobile = ({ title, data }: any) => {
  const [exp, setExp] = useState(-1);

  const handleClick = (index: number) => {
    exp != index ? setExp(index) : setExp(-1);
  };

  return (
    <S.LandingFaq id="landing_faq_section_mobile">
      <H4Tag margin="0 0 40px" color="#000">
        {title}
      </H4Tag>
      <S.LandingFaqList>
        {data?.map((item: any, index: number) => {
          return (
            <S.LandingFaqListItem
              className={`${exp == index ? "open" : "close"}`}
              key={index}
              onClick={() => handleClick(index)}
            >
              <S.LandingFaqQues>{item.ques}</S.LandingFaqQues>
              {exp == index && <PlainMarkdown>{item.info}</PlainMarkdown>}
            </S.LandingFaqListItem>
          );
        })}
      </S.LandingFaqList>
    </S.LandingFaq>
  );
};

export default LandingFaqMobile;
